
// .chat-container {
//   height: 300px; /* 设置聊天容器的高度，保持滚动条可见 */
//   overflow-y: auto; /* 当消息内容超出容器高度时显示滚动条 */
// }
.community-promote {
  display: flex;
  /*width: 100vw;*/
  height: 100vh;
  overflow: hidden;
  .tab-bar {
    width: 60px;
    color: #fff;
    background: #2a2a2c;
    display: flex;
    flex-direction: column;
    .user-box {
      height: 80px;
      display: flex;
      .user-avatar {
        display: flex;
        margin: auto;
        position: relative;
        .user-img {
          width: 34px;
          height: 34px;
          border: 2px solid #fff;
          border-radius: 2px;
          box-sizing: border-box;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }
  .menu {
    flex: 1;
    height: 1%;
    .menu-item {
      width: 100%;
      line-height: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .iconfont {
        font-size: 22px;
      }
      .text {
        font-size: 16px;
        margin-top: 10px;
      }
      &.current {
        color: #07c160;
      }
    }
  }
  .contact-bar {
    width: 250px;
    display: flex;
    flex-direction: column;
    background: #f5f8ff;
    .user-info {
      display: flex;
      align-items: center;
      height: 60px;
      padding: 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #e4e1e1;
    }
    .user-container {
      height: 1%;
      flex: 1;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #eaeaea;
      .user-content {
        // height: 500px;
        // height: 1%;
        flex: 1;
        ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
          max-height: 730px;
        }
        :deep(.el-scrollbar__wrap){
          overflow-x: hidden;
          max-height: 730px;
        }
      
        .levelOne-item {
          display: flex;
          cursor: pointer;
          padding: 0 15px;
          line-height: 40px;
          .iconfont {
            color: #bfbfbf;
          }
          .text {
            width: 1%;
            flex: 1;
            font-weight: bold;
            margin-left: 6px;
          }
        }
      }
    }
    .searchUser-input {
      ::v-deep {
        .el-input__inner {
          width: 96%;
          height: 24px;
          line-height: 24px;
          color: #fff;
          font-size: 12px;
          border-color: #dbd9d8;
          background-color: #dbd9d8;
        }
        .el-input__prefix {
          color: #666666;
        }
        .el-input__icon {
          line-height: 24px;
          &.el-icon-search {
            cursor: pointer;
          }
        }
      }
    }
    .content {
      width: 1%;
      flex: 1;
      color: #666;
      font-size: 12px;
      margin-left: 10px;
      .top {
        display: flex;
        align-items: center;
        .name {
          color: #333;
          font-size: 14px;
          width: 1%;
          flex: 1;
        }
        .wait-time {
          margin-left: 10px;
        }
      }
      .bottom {
        display: flex;
        margin-top: 4px;

        .message {
          flex: 1;
          width: 1%;
        }
      }
    }
  }
  .main-container {
    width: 1%;
    flex: 1;
    display: flex;
    flex-direction: column;
    .main-top {
      height: 60px;
      border-bottom: 1px solid #eaeaea;
      box-sizing: border-box;
      padding: 0 0 0 20px;
      display: flex;
      align-items: center;
      //   background: #f5f5f5;
      .user-info {
        display: flex;
        width: 1%;
        flex: 1;
      }
      .more-icon {
        cursor: pointer;
        padding-right: 10px;

        i {
          font-size: 22px;
        }
      }
    }
    .chat-container {
      flex: 1;
      height: 1%;
      background: #f0f1f1;
      & > ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
        & > .el-scrollbar__view {
          min-width: 500px;
        }
      }
      .scene-box {
        padding: 12px 20px;
        background: #fff;
        display: flex;
        align-items: center;
        .scene-text {
          width: 1%;
          flex: 1;
        }
        .link-blue-shadow {
          margin-left: 40px;
        }
      }
      .chat-content {
        padding: 12px 20px 0;
        .chat-item {
          display: flex;
          margin-bottom: 24px;
          .chat-user {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            border-radius: 2px;
            overflow: hidden;
            margin-top: 36px;
            img {
              max-width: 100%;
            }
          }
          .chat-message {
            width: 1%;
            flex: 1;
            margin-left: 14px;
            .time {
              color: #fff;
              font-size: 12px;
              padding: 4px 0;
              background: #dadada;
              border-radius: 2px;
              text-align: center;
              width: 68px;
              margin: 0 auto;
            }
            .edit-text {
              text-align: center;
              margin-top: 4px;
              font-size: 14px;
              color: #999;          
            }
            .line {
              color: #999;
              line-height: 1;
              display: flex;

              .text-overflow {
                max-width: 50%;
              }
            }
            .message-wrapper {
              display: flex;
              align-items: flex-end;
            }
            .message-box {
              margin-top: 2px;
              background: #98e165;
              padding: 10px 10px;
              border-radius: 4px;
              display: inline-block;
              position: relative;
              line-height: 20px;
              max-width: 50%;
              box-sizing: border-box;
              &:before {
                content: "";
                position: absolute;
                top: 14px;
                left: -6px;
                width: 0;
                height: 0;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-right: 6px solid #98e165;
              }
            }
          }
          &.current {
            flex-direction: row-reverse;
            text-align: right;
            .chat-message {
              margin-left: 0;
              margin-right: 14px;
              text-align: right;
              .line {
                flex-direction: row-reverse;
              }
            }
            .message-wrapper {
              flex-direction: row-reverse;
            }
            .message-box {
              background: #98e165;
              text-align: left;
              margin-top: 10px;
              word-wrap: break-word;

              &:before {
                content: none;
              }
              //   &:after {
              //     content: "";
              //     position: absolute;
              //     top: 14px;
              //     right: -6px;
              //     width: 0;
              //     height: 0;
              //     border-top: 6px solid transparent;
              //     border-bottom: 6px solid transparent;
              //     border-left: 6px solid #98e165;
              //   }
            }
          }
        }
      }
    }
    .send-message {
      height: 220px;
      .default-emoji {
        height: 40px;
        border-top: 1px solid #eee;
        .emoji-item {
          padding: 0 4px;
          position: relative;
          cursor: pointer;
          i {
            font-size: 24px;
          }
        }
      }
    }
  }
  .more-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .userinfo-content{
      height:calc(100vh - 251px);
      .el-scrollbar{
        height: 100%;
        overflow-y: auto;
      }
    }
    .more-bottom {
    height: 52px;
    width: 100%;
    text-align: center;
    line-height: 52px;
    background: #fff;
    // padding: 10px 0;
    color: #ff0000;
    border-top: 1px solid #eee;
    cursor: pointer;
  }
    .more-top {
      padding: 20px 20px 0;
      box-sizing: border-box;
     
      label {
        font-size: 14px;
        // color: #999;
        color: #000000 ;
      }
      div {
        margin-bottom: 10px;
      }
      .edit-input {
        input {
          width: 200px;
          height: 24px;
          background: #ffffff;
          border: 1px solid #e5e5e5;
        }
      }
    }
    .edit-group {
      cursor: pointer;
      padding-bottom: 20px;
      border-bottom: 1px solid #e5e5e5;
      overflow: hidden; /* 超出部分隐藏 */
      white-space: nowrap; /* 不换行 */
      text-overflow: ellipsis;
      color: #7F7F7F;
      margin-top: 5px;
      font-size: 14px;
      
    }
  }
  .levelTwo-item {
    height: 54px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 20px 0 25px;
    transition: all 0.3s;
    cursor: pointer;
    // border-bottom: 1px solid #eee;
    .user-avatar {
      width: 34px;
      height: 34px;
      border-radius: 2px;
      overflow: hidden;
      display: flex;
      img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }
      .avatar {
        filter: grayscale(100%);
        &.current {
          filter: grayscale(0);
        }
      }
    }
  }

  .dialog-footer {
    text-align: center;
    .text-input {
      width: 100%;
      height: 80px;
    }
    .el-button {
      border: 1px solid #2dc079;
      border-radius: 4px;
      text-align: center;
      color: #2dc079;
    }
    .cancel {
      border: 1px solid #ccc;
      color: #555;
    }
    .confirm {
      background-color: rgb(28, 176, 100);
      color: #fff;
    }
  }
  em {
    font-style: normal;
  }
}

::v-deep .el-message-box__btns {
  text-align: center !important;
}
::v-deep .el-drawer rtl {
  width: 20% !important;
}
::v-deep .el-drawer__body{
  width: 300px;
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  // max-height: 760px;
  height: 100%;
}
// ::v-deep .el-textarea__inner:focus{
//     border: none;
// }
::v-deep .el-textarea__inner {
  border: none;
  height: 260px;
  border-top: 1px solid #999;
  border-radius: 0px;
}
::v-deep .el-dialog__body {
  padding: 0 20px !important;
}
.dialog-header-title {
  text-align: center;
  font-size: 16px;
  padding: 10px 0;
}
.send-message {
  display: flex;
  flex-direction: column;
}
.input-box {
  width: 100%;
  ::v-deep .el-textarea__inner {
    border: none;
    height: 140px;
    border-radius: 0px;
    font-size: 16px;
  }
}
.send-message-input {
}
.send-bottom {
  display: flex;
  justify-content: flex-end;
}
.send-button {
  background-color: rgb(28, 176, 100);
  color: #fff;
  margin: 10px;
}
.user-name {
  display: flex;
  //   justify-content: center;
  .user-name-mark {
    font-size: 16px;
  }
}
.group-chat-name {
  color: #666666;
  font-size: 12px;
}
.emoji {
  position: absolute;
  bottom: 24px;
  left: 0;
  z-index: 100;
  transform: translate(-50%,0);
}
::v-deep .el-input__inner{
  width: 200px;
  height: 30px;
}
.group_lable{
  height: 40px;
  font-size: 14px;
  .text{
    line-height: 26px;
    color: #7F7F7F ;
  }
}
.edit-text{
  padding-top: 5px;
}
